
import Chrome from "../../assets/services/message.png";

import { SketchOutlined } from "@ant-design/icons";



export default function Automate({ youtube_url, onclick }) {
  return (
    <div className="main-content">
      <section id="how-it-works" className="overview-block-ptb it-works bg-white">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center">
            {/* Image Section */}
            <div className="w-full lg:w-1/2 p-4">
              <div className="text-center">
                <img src={Chrome} className="w-full max-w-md mx-auto" alt="Chrome" />
              </div>
            </div>

            {/* Content Section */}
            <div className="w-full lg:w-1/2 p-4">
              <div className="text-left">
                <h5 className="font-bold text-xl uppercase mt-6 mb-4">
                  Boost Your LinkedIn Outreach with swiftlead Automation
                </h5>
                <p className="text-gray-700 mb-6">
                  Take your LinkedIn experience to the next level with swiftlead, the Chrome extension designed to automate your outreach. Whether you're hunting for job opportunities or looking for clients, swiftlead helps you connect with the right people without the hassle.
                </p>

                {/* Feature List */}
                <ul className="space-y-3">
                  <li className="flex items-center">
                    <span className=" mr-2"><SketchOutlined className="text-blue-800 text-[12px] " /></span>
                    Send personalized messages to more people in less time.

                  </li>
                  <li className="flex items-center">
                    <span className=" mr-2"><SketchOutlined className="text-blue-800 text-[12px] " /></span>
                    Target the right people with customized outreach.


                  </li>
                  <li className="flex items-center">
                    <span className=" mr-2"><SketchOutlined className="text-blue-800 text-[12px] " /></span>
                    Increase your outreach volume while maintaining a personal touch.

                  </li>
                  <li className="flex items-center">
                    <span className=" mr-2"><SketchOutlined className="text-blue-800 text-[12px] " /></span>
                    Visit profiles and send messages with ease.

                  </li>
                  <li className="flex items-center">
                    <span className=" mr-2"><SketchOutlined className="text-blue-800 text-[12px] " /></span>
                    Boost productivity and save time on LinkedIn outreach.

                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}