import { useState } from "react";
import { createClient } from "../../utils/supabase/client";
import { useNavigate } from "react-router";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { getStripe } from "../../utils/stripe/client";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, message as AntMessage } from "antd";
import { message } from "antd";
import { useAuth } from "../../contexts/Auth";

const { confirm } = Modal;

export default function Pricing({
  user,
  products,
  subscription,
  isPhoneVerified,
  showConfirm,
  confirmAddNumber,
  isNumberExists,
}) {
  const { logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const supabase = createClient();

  const isNumberExistsInUserData = isNumberExists ? true : false;

  // Function to handle logic for adding or verifying the number
  function handleAddOrVerifyNumber() {
    if (!isNumberExistsInUserData) {
      // If the number doesn't exist, prompt the user to add it
      confirmAddNumber();
    } else if (!isPhoneVerified) {
      // If the number exists but isn't verified, prompt the user to verify it
      showConfirm();
    }
  }

  const handleStripeCheckout = async (price) => {
    setIsLoading(true);

    try {
      const userToken = (await supabase.auth.getSession()).data.session
        .access_token;
      const resp = await fetch("/api/stripe/buyPlan", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ price }),
      });

      const json = await resp.json();

      if (resp.ok) {
        const { sessionId } = json;
        const stripe = await getStripe();
        await stripe.redirectToCheckout({ sessionId });
      } else {
        AntMessage.error(json.error);
        setIsLoading(false);
      }
    } catch (err) {
      AntMessage.error("An unexpected error occurred." || err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTrialSubscription = async (price) => {
    setIsLoading(true);

    console.log(price, "price");

    try {
      const userToken = (await supabase.auth.getSession()).data.session
        .access_token;

      const resp = await fetch("/api/stripe/trialPlan", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ price }),
      });

      const json = await resp.json();

      if (resp.ok) {
        AntMessage.success("Trial subscription created successfully!");

        // Redirect or refresh after showing the message
        setTimeout(() => {
          // Example: refresh window
          window.location.reload();
        }, 1000);
      } else {
        messageApi.destroy();
        AntMessage.error(json.error);
      }
    } catch (err) {
      messageApi.destroy();
      AntMessage.error("An unexpected error occurred." || err.message);
    } finally {
      messageApi.destroy();
      setIsLoading(false);
    }
  };

  const showPromiseConfirm = (operation, operationType) => {
    if (operationType === "trial") {
      try {
        operation(); // Directly call the operation for trial
      } catch (error) {
        console.error("Trial operation failed:", error);
      }
    } else {
      confirm({
        title: "Do you want to proceed with this subscription?",
        icon: <ExclamationCircleFilled />,
        content: "Click OK to confirm your action.",
        onOk() {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              try {
                operation(); // Call the operation for subscription
                resolve(); // Resolve the promise on success
              } catch (error) {
                console.error("Operation failed:", error);
                reject(); // Reject the promise on failure
              }
            }, 1000); // Simulate a delay for the operation
          }).catch(() => console.log("Oops! Something went wrong."));
        },
        onCancel() {
          console.log("Operation canceled.");
        },
        maskClosable: true,
        closable: true,
      });
    }
  };

  // Helper function to extract the unit amount for month/week interval
  const getUnitAmount = (product) => {
    const price = product?.prices?.find(
      (price) => price.interval === "month" || price.interval === "week"
    );
    // If no price is found, return a high value to push it to the end (or 0 based on your needs)
    return price ? price.unit_amount || 0 : Number.MAX_SAFE_INTEGER;
  };

  // Sort products from low to high
  const sortedProducts = [...products].sort((a, b) => {
    return getUnitAmount(a) - getUnitAmount(b);
  });

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (!products.length) {
    return (
      <>
        {contextHolder}
        <section className="h-screen" data-theme="dark">
          <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
            <div className="sm:flex sm:flex-col sm:align-center"></div>
            <p className="text-4xl font-extrabold text-white sm:text-center sm:text-6xl">
              No subscriptions available.
            </p>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <>
        <section data-theme="dark">
          <div className="mx-auto sm:px-6 lg:px-8">
            <div className="md:flex md:gap-4">
              {/* main pack */}

              <div className="mt-2 space-y-4 sm:mt-16 sm:space-y-0 flex flex-wrap justify-center gap-6 w-full">
                {sortedProducts.map((product) => {
                  // Find the price for the month interval
                  const price = product?.prices?.find(
                    (price) =>
                      price.interval === "month" || price.interval === "week"
                  );

                  if (!price) {
                    return (
                      <div
                        key={product.id}
                        className="flex flex-col rounded-lg shadow-sm divide-y divide-gray-600 bg-gray-800 max-w-xs mx-auto items-center"
                      >
                        <h2 className="text-2xl font-semibold leading-6 text-white">
                          {product.name}
                        </h2>
                        <p className="mt-4 text-gray-300">Price unavailable</p>
                      </div>
                    );
                  }

                  // Determine the price string
                  const priceString =
                    price.id === "price_1QTK38BZICJUgWJfR5u4pvsv"
                      ? "$0.00"
                      : new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: price.currency,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format((price?.unit_amount || 0) / 100);

                  return (
                    <div
                      key={product.id}
                      className={`flex flex-col rounded-lg shadow-sm divide-y divide-gray-600 bg-gray-800 ${
                        subscription
                          ? product.name ===
                            subscription?.prices?.products?.name
                            ? "border border-pink-500"
                            : ""
                          : product.name === "Freelancer"
                          ? "border border-pink-500"
                          : ""
                      } flex-1 basis-full md:basis-1/2 lg:basis-1/3 xl:basis-1/4 max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg mx-auto items-center`}
                    >
                      <div className="flex flex-col h-full p-6 w-64">
                        <h2 className="text-2xl font-semibold leading-6 text-white">
                          {product.name}
                        </h2>
                        <div className="mt-auto">
                          <p className="mt-8">
                            <span className="text-4xl font-extrabold text-gray-300">
                              {priceString}
                            </span>
                            <span className="text-base font-medium text-gray-100">
                              {price.id === "price_1QTK38BZICJUgWJfR5u4pvsv"
                                ? ""
                                : "/ week"}
                            </span>
                          </p>

                          <button
                            className="mt-5 w-full bg-[#536cef] text-white py-2 px-4 rounded hover:bg-[#4a58d4]"
                            onClick={() => {
                              if (
                                price.id === "price_1QTK38BZICJUgWJfR5u4pvsv"
                              ) {
                                if (
                                  !isNumberExistsInUserData ||
                                  !isPhoneVerified
                                ) {
                                  handleAddOrVerifyNumber();
                                  return;
                                }
                                showPromiseConfirm(
                                  () => handleTrialSubscription(price),
                                  "trial"
                                );
                              } else {
                                showPromiseConfirm(
                                  () => handleStripeCheckout(price),
                                  "subscription"
                                );
                              }
                            }}
                          >
                            {price.id === "price_1QTK38BZICJUgWJfR5u4pvsv"
                              ? "Start Free Trial"
                              : "Subscribe"}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {!user ? (
              <div className="text-center mt-3">
                <button
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                  onClick={() => navigate("/sign-in")}
                >
                  Sign In
                </button>
              </div>
            ) : (
              <div className="mt-3 text-center">
                <button
                  className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 mt-5"
                  onClick={logout}
                >
                  Log out
                </button>
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
}
