import Chrome from "../../assets/services/icon1.png";
import Plan from "../../assets/services/icon2.png";
import Rocket from "../../assets/services/icon3.png";


export default function Works({ youtube_url, onclick }) {
  return (
    <div className="main-content">
      <section id="how-it-works" className="overview-block-ptb it-works bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="heading-title">
                <h2 className="title iq-tw-7">3 Simple Steps To Launch</h2>
                <p>
                  Getting started with swiftlead is a simple, fast process,
                  unlocking unmatched
                  <br />
                  productivity and efficiency has never been easier.
                </p>
                <div>
                  <button
                    onClick={() =>
                      window.open("https://www.youtube.com/watch?v=z3DfDjyrGbE", "_blank")
                    }
                    className="button button-glass"
                  >
                    Watch Tutorial
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Step 1 */}
            <div className="col-md-12 col-lg-4 r-mt-30">
              <div className="iq-works-box text-center items-center flex flex-col h-full">
                <div className="step">1</div>
                <div className="icon-bg text-center ">
                  <img src={Plan} className="img-fluid " alt="Plan" />
                </div>
                <h5 className="iq-tw-7 text-uppercase iq-mt-25 iq-mb-15">Plan</h5>
                <h5 className="text-[18px]">Sign up for our plan.</h5>
                <p>Pick a plan and get ready to go.</p>
              </div>
            </div>
            {/* Step 2 */}
            <div className="col-md-12 col-lg-4">
              <div className="iq-works-box text-center items-center flex flex-col h-full">
                <div className="step">2</div>
                <div className="icon-bg text-center">
                  <img src={Chrome} className="img-fluid" alt="Chrome" />
                </div>
                <h5 className="iq-tw-7 text-uppercase iq-mt-25 iq-mb-15">Chrome Extension</h5>
                <h5 className="text-[18px]">Download our chrome extension.</h5>
                <p>Start by adding swiftlead to your browser.</p>
              </div>
            </div>
            {/* Step 3 */}
            <div className="col-md-12 col-lg-4 r-mt-30">
              <div className="iq-works-box text-center items-center flex flex-col h-full">
                <div className="step">3</div>
                <div className="icon-bg text-center">
                  <img src={Rocket} className="img-fluid" alt="Rocket" />
                </div>
                <h5 className="iq-tw-7 text-uppercase iq-mt-25 iq-mb-15">Enjoy</h5>
                <h5 className="text-[18px]">Let swiftlead work its magic.</h5>
                <p>Relax while swiftlead connects you with opportunities.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
