import "./index.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import LandingPage from "./pages/LandingPage/LandingPage";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./contexts/Auth";
import Subscriptions from "./pages/Dashboard/Subscriptions";
import SignIn from "./pages/SignIn/SignIn";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import NotFoundPage from "./NotFoundpage"
import HowItWorks from "./pages/HowItWorks/Howitworks";
import WhyUs from "./pages/Whyus/Whyus";
import ContactUs from "./pages/Contact/Contact";
import BlogPage from "./pages/Blog/BlogSite";
import BlogPostLayout from "./pages/Blog/SingleBlog";
import PricingService from "./pages/price/priceing";



//check


function App() {

  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/sign-in" element={<SignIn />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route exact path="/blog" element={<BlogPage />} />
          <Route exact path="/blog/:id" element={<BlogPostLayout />} />

          <Route exact path="/how-it-works" element={<HowItWorks />} />


          <Route exact path="/why-us" element={<WhyUs />} />
          {/* <Route exact path="/postblog" element={<PostBlog />} /> */}
          <Route exact path="/pricing" element={<PricingService />} />


          <Route exact path="/contact" element={<ContactUs />} />
          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route exact path="/dashboard" element={<PrivateRoute />}>
            <Route exact path="/dashboard" element={<Subscriptions />} />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;

//vercel account:
//email: zach.hayward93@gmail.com
//password: Hannah11304!

//supabase account:
//email: zach.hayward93@gmail.com
//password: Hannah11304!
//db password: BigLinkLeads##
//
