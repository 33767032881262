import { CheckCircleFilled, SketchOutlined } from '@ant-design/icons';
import Chrome from "../../assets/services/Safe.png";

export default function SafeAutomationBanner({ youtube_url, onclick }) {
  const benefits = [
    { 
      title: "Automated Job & Client Search",
      description: "Let swiftlead automate your LinkedIn search for jobs and clients, saving time and boosting efficiency."
    },
    { 
      title: "Real-Time Connections",
      description: "Connect instantly with decision-makers and organizations, growing your network effortlessly."
    },
    { 
      title: "Save Time",
      description: "Skip the scrolling and focus on interviews—swiftlead speeds up the process."
    },
    { 
      title: "Enhanced Productivity",
      description: "Boost productivity while swiftlead handles the heavy lifting."
    },
    { 
      title: "Secure & Reliable",
      description: "swiftlead ensures secure, genuine connections without compromising your privacy or LinkedIn account."
    }
  ];

  return (
    <div className="main-content relative">
      {/* Skewed Background */}
      <div className="absolute inset-0  origin-top-left z-0"></div>

      <section id="how-it-works" className="overview-block-ptb it-works relative z-10">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center">
            {/* Image Section */}
            <div className="w-full lg:w-1/2 p-4">
              <div className="text-center">
                <img src={Chrome} className="w-full max-w-md mx-auto" alt="Chrome" />
              </div>
            </div>

            {/* Content Section */}
            <div className="w-full lg:w-1/2 p-4">
              <div className="text-left">
                {/* <h2 className="font-bold text-[30px] uppercase mt-6 mb-4">
                  Benefits of Using swiftlead
                </h2> */}
                <div className="heading-title">
                <h2 className="title "> Benefits of Using swiftlead</h2>
                
                </div>
                <ul className="mb-6 space-y-4">
                  {benefits.map((benefit, index) => (
                    <li key={index} className=" items-center">
                      {/* <CheckCircleFilled className="text-blue-500 text-[10px] mr-2 mt-1" /> */}
                      <div>
                        <h5 className="font-semibold text-[14px]"><SketchOutlined className='text-[12px]'/> {benefit.title}</h5>
                        <p className="text-sm text-gray-600 mt-1">
                          {benefit.description}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
