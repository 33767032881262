import { 
  UserOutlined, 
  SolutionOutlined, 
  SearchOutlined, 
  UsergroupAddOutlined,
  RocketOutlined,
  NotificationOutlined,
  CheckCircleOutlined 
} from "@ant-design/icons";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

const cards = [
  { icon: <UserOutlined />, title: "Job Seekers", desc: "swiftlead helps job seekers quickly connect with hiring managers and find the right opportunities." },
  { icon: <SolutionOutlined />, title: "Businesses", desc: "For businesses, swiftlead automates client and partner outreach to drive growth." },
  { icon: <SearchOutlined />, title: "Freelancers", desc: "Freelancers use swiftlead to easily target and connect with potential clients." },
  { icon: <UsergroupAddOutlined />, title: "Sales Teams", desc: "Sales teams automate outreach and build relationships with prospects, boosting productivity." },
  { icon: <RocketOutlined />, title: "Entrepreneurs", desc: "Entrepreneurs can grow their network and customer base with swiftlead’s automated outreach." },
  { icon: <NotificationOutlined />, title: "Marketing Professionals", desc: "Marketing professionals automate outreach and build connections with clients and industry leaders." },
];

const Compatible = () => {
  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
          Our LinkedIn Automation Tool Works Best for:
        </h2> */}
        <div className="heading-title">
                <h2 className="title iq-tw-7"> Our LinkedIn Automation Tool Works Best for:</h2>
                
                </div>
        <Swiper
          slidesPerView={4}
          spaceBetween={30}
          breakpoints={{
            320: { slidesPerView: 1 },
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="pb-12"
        >
          {cards.map((card) => (
            <SwiperSlide key={card.title}>
              <div className="group relative mb-9 bg-white rounded-xl shadow-md hover:shadow-lg h-full transition-all duration-300 ease-out hover:-translate-y-2 mx-2">
                <div className="absolute inset-0 bg-gradient-to-br from-blue-100/70 to-purple-100/70 opacity-0 group-hover:opacity-100 rounded-xl transition-opacity duration-300 z-10" />
                <div className="p-8 flex flex-col items-center text-center h-full relative z-20">
                  <div className="mb-4 flex items-center justify-center relative">
                    <span className="absolute w-16 h-16 bg-blue-100 rounded-full group-hover:bg-blue-200 transition-colors duration-300" />
                    <span className="text-3xl text-blue-600 transform group-hover:scale-110 transition-transform duration-300">
                      {card.icon}
                    </span>
                  </div>
                  <h3 className="text-xl font-semibold text-gray-800 mb-2 min-h-[60px] flex items-center">
                    {card.title}
                  </h3>
                  <p className="text-gray-600 text-sm mb-4 flex-1">
                    {card.desc}
                  </p>
                  <CheckCircleOutlined className="text-green-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-xl" />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Compatible;