import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/logo.png";
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TikTokOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

const Footer = () => {
  const [socialLinks] = useState([
    {
      id: 1,
      icon: <FacebookOutlined />,
      url: "https://facebook.com",
      name: "Facebook",
    },
    {
      id: 2,
      icon: <InstagramOutlined />,
      url: "https://instagram.com",
      name: "Instagram",
    },
    {
      id: 3,
      icon: <LinkedinOutlined />,
      url: "https://linkedin.com",
      name: "LinkedIn",
    },
    {
      id: 4,
      icon: <TikTokOutlined />,
      url: "https://tiktok.com",
      name: "TikTok",
    },
    {
      id: 5,
      icon: <YoutubeOutlined />,
      url: "https://youtube.com",
      name: "YouTube",
    },
  ]);

  return (
    <>
      <section className="contact-area bg-gray-900 text-white py-16 px-6" id="contact">
        
        <div className="row ">
          <div className="col-sm-4">
            <div className="flex gap-2 ">
              <div className="text-white">

              </div>
              <div className="services-content">
                <a href="/">
                  <img src={Logo} alt="SwiftLead Logo" className="w-36" />
                </a>
                <p>
                  swiftlead delivers essential data directly from job
                  postings, giving you quick access to decision-makers'
                  profiles, company details, and job specifics. Whether
                  you’re pursuing a new job or new clients, you'll have
                  everything you need to connect effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="flex gap-2 iq-services-box text-left iq-font-white">
              <div className="iq-icon text-white">

              </div>
              <div className="services-content">
                <h3 className="text-2xl font-semibold">Quick Links</h3>
                <ul className="space-y-3 text-gray-300">
                  <li><a href="/why-us" className="hover:text-blue-400 transition text-white">Why Us</a></li>
                  <li><a href="/blog" className="hover:text-blue-400 transition text-white">Blog</a></li>
                  <li><a href="/contact" className="hover:text-blue-400 transition text-white">Contact</a></li>
                  <li><a href="/privacy-policy" className="hover:text-blue-400 transition text-white">Privacy Policy</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="flex gap-2 iq-services-box text-left iq-font-white">
              <div className="iq-icon text-white">

              </div>
              <div className="services-content">
                <h3 className="text-2xl font-semibold">Support</h3>
                <p className="text-white text-sm">
                  Have questions or need assistance? We're here to help!
                </p>
                <a
                  href="mailto:support@swiftlead.com"
                  className="text-blue-400 hover:text-blue-300 transition"
                >
                  support@swiftlead.com
                </a>

                <h3 className="text-2xl font-semibold mt-6">Follow Us</h3>
                <div className="flex space-x-6">
                  {socialLinks.map(({ id, icon, url, name }) => (
                    <a
                      key={id}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={name}
                      className="text-3xl text-white hover:text-blue-400 transition-transform transform hover:scale-110"
                    >
                      {icon}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Bottom */}
<footer style={{ backgroundColor: "black", padding: "20px" }}>
  <div className="text-center text-white">
    © {new Date().getFullYear()} All rights reserved. swiftlead - LinkedIn automation tool.
    <NavLink to={"/privacy-policy"} style={{ color: "#5271ff" }}>
      {" "}Privacy
    </NavLink>{" "}and{" "}
    <NavLink to={"/terms-and-conditions"} style={{ color: "#5271ff" }}>
      Terms
    </NavLink>{" "}.
  </div>
</footer>
    </>
  );
};

export default Footer;