import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Logo from "../../assets/logo.png";
import Drive from "../../assets/drive/07.png";
import Rocket from "../../assets/services/icon3.png";
import Chrome from "../../assets/services/icon1.png";
import Plan from "../../assets/services/icon2.png";
import Works from "../../components/parts/works";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";


export default function HowItWorks() {
  const [youtube_url, setYoutubeUrl] = useState('');

  const setSignInAndSignUpButtonStateToLocalStorage = (type) => {
    if (type === "signup") {
      localStorage.setItem("buttonState", "signup");
    } else if (type === "signin") {
      localStorage.setItem("buttonState", "signin");
    }
  };

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const response = await fetch(`/api/social/settings`);
        const data = await response.json();
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const apiLinks = data?.data[0]; // Assuming this contains your social link data

        if (apiLinks) {
          setYoutubeUrl(apiLinks.youtube_video_link)
        } else {
          console.error('No social links found in API.');
        }
      } catch (error) {
        console.error('Error fetching social links:', error);
      }
    };

    fetchSocialLinks();
  }, []);

  return (
    <>
      <Header />

      <Helmet>
        <title>How swiftlead Works: Automated Client and Job Search Tool </title>
      </Helmet>
      <section
        id="iq-home"
        className="iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax"
        data-jarallax='{"speed": 0.6}'
        style={{ background: "url(images/bg/01.jpg)" }}
      >

        <div className="container-fluid">
          <div className="banner-text">
            <div className="row justify-content-between">
              <div className="col-lg-6 align-self-center">

                <h1 className="text-uppercase iq-font-white iq-tw-3 text-[24px]!">
                  Connects users with decision-makers
                  <span className="italic">
                    {" "}
                    —All-in-One Platform for Job Seekers.

                  </span>
                </h1>

                <p className="iq-font-white iq-pt-18 iq-mb-40">
                  Swiftlead is a platform designed to help users find jobs. It features both a Chrome Extension and an app, making it accessible across different devices.
                </p>


              </div>
              <div className="col-lg-6">

              </div>
            </div>
          </div>
        </div>
        <div className="banner-objects">
          <span
            className="banner-objects-01"
            data-bottom="transform:translatey(50px)"
            data-top="transform:translatey(-50px);"
          >
            <img src={Drive} alt="drive02" />
          </span>
          <span className="banner-objects-02 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
          <span className="banner-objects-03 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
        </div>
      </section>





      <Works youtube_url={youtube_url} onclick={() =>
        setSignInAndSignUpButtonStateToLocalStorage("signup")
      } />




      <Footer />

    </>
  )
}