import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Logo from "../../assets/logo.png";
import Drive from "../../assets/drive/07.png";
import { CaretRightOutlined, FacebookOutlined, LinkedinOutlined, TwitterOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { generateDate } from "../../utils/datehelper";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
export default function BlogPostLayout() {
  const { id: slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [isloading, setisloading] = useState(false);
  const [allcat, setallcat] = useState([]);
  const [nodatafound, setNodatafound] = useState(null)
  const [isallcatload, setisallcatload] = useState(false);


  useEffect(() => {
    async function fetchBlog() {
      if (!slug) {
        return
      }
      try {
        setisloading(true)
        // const response = await fetch(`/api/blogs/singleblog?id=${id}`);
        const response = await fetch(`/api/blogs/singleblog?id=${slug}`);
        const result = await response.json();
        if (response.ok) {
          if (!result.data) {
            setNodatafound("Ohho! No blog is available.")
            return;
          }
          setBlog(result.data);
          setNodatafound(null);
        }

      } catch (error) {
        setNodatafound("Something Went Wrong!")
      }
      finally {
        setisloading(false)
      }
    }
    fetchBlog();
  }, [slug]);


  useEffect(() => {
    async function fetchcat() {
      try {
        setisallcatload(true)
        const response = await fetch("/api/blogs/categories", {
          method: "GET",
          headers: {
            "Content-Type": "application/json", // Ensure proper content type
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          // console.error("Error fetching blogs:", errorData);
        } else {
          const result = await response.json();
          setallcat(result.data)
        }
      } catch (error) {

      }
      finally {
        setisallcatload(false)
      }
    }
    fetchcat()
  }, [])

  return (
    <>
      <Header />

      <section
        id="iq-home"
        className="iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax"
        data-jarallax='{"speed": 0.6}'
        style={{ background: "url(images/bg/01.jpg)" }}
      >
        <div className="container-fluid">
          <div className="banner-text">
            <div className="row justify-content-between">
              <div className="col-lg-6 align-self-center">

                <p className="iq-font-white iq-pt-18 iq-mb-40 text-lg">
                  Swiftlead Blog Details</p>
                <h4 className="text-uppercase iq-font-white iq-tw-3  text-2xl">
                  {blog?.name} <br />
                </h4>
              </div>

            </div>
          </div>
        </div>
        <div className="banner-objects">
          <span
            className="banner-objects-01"
            data-bottom="transform:translatey(50px)"
            data-top="transform:translatey(-50px);"
          >
            <img src={Drive} alt="drive02" />
          </span>
          <span className="banner-objects-02 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
          <span className="banner-objects-03 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
        </div>
      </section>
      <div className="container mx-auto px-4 py-8">
        {/* Main Layout */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column: Image + Content */}
          <div className="col-span-2 space-y-6">
            {/* nodatafound */}
            {
              !isloading && nodatafound && <p>{nodatafound}</p>
            }

            {
              isloading && !blog && !nodatafound && <div className=" p-4 animate-pulse">
                <div className="w-full h-48 bg-gray-300 rounded-md"></div>
                <div className="mt-4 h-6 w-1/2 bg-gray-300 rounded"></div>
                <div className="mt-2 h-4 w-3/4 bg-gray-300 rounded"></div>
                <div className="mt-2 h-4 w-5/6 bg-gray-300 rounded"></div>
                <div className="mt-4 h-10 w-full bg-gray-300 rounded"></div>
              </div>
            }

            {
              blog && <>
                <div className="relative">
                  <img
                    src={blog.image}
                    alt="Serverless Architecture"
                    // h-72
                    className="w-full   h-auto
                 object-cover rounded-lg shadow-lg"
                  />
                </div>
                <div className="space-y-4">
                  <div className="text-sm text-gray-600 flex items-center flex-wrap">
                    {blog.writer_name && (
                      <>
                        <span>By <span className="text-blue-600">{blog.writer_name}</span></span>
                        <span className="mx-2 text-gray-400">|</span>
                      </>
                    )}
                    <span>{generateDate(blog.created_at)}</span>
                  </div>
                  <h1 className="text-3xl font-semibold text-gray-800">
                    {blog.name}
                  </h1>
                  <div
                    className="text-gray-700"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog.description) }}
                  />

                </div>
              </>
            }
          </div>

          {/* Right Column: Categories & Search */}
          <aside className="space-y-8">
            {/* Categories */}
            <div className="bg-white shadow-md rounded-md p-4 mb-6">
              <h3 className="text-xl font-semibold mb-4 text-black">Categories</h3>
              <ul className="space-y-2 text-gray-700">
                {
                  isallcatload && <>

                    <div className="mt-4 h-6 w-1/2 bg-gray-300 rounded  animate-pulse"></div>
                    <div className="mt-2 h-4 w-3/4 bg-gray-300 rounded  animate-pulse"></div>
                    <div className="mt-2 h-4 w-5/6 bg-gray-300 rounded  animate-pulse"></div>

                  </>
                }
                {
                  !isallcatload && allcat?.map((category, index) => (
                    <li key={index} className=" text-black border-b-[1px]  border-slate-5000">
                      <p>
                        <Link to={`/blog?search=${category.name}`} className='text-black'>
                          <CaretRightOutlined className='text-blue-600' /> {category.name}
                        </Link>
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
            {/* Subscribe */}

          </aside>


        </div>

        {
          blog && <ShareButtons blogId={blog.uuid} blogTitle={blog.name} />
        }
      </div>

     

      <Footer />
    </>
  );
}



const ShareButtons = ({ blogId: id, blogTitle }) => {
  const currentUrl = typeof window !== "undefined" ? window.location.origin : "";
  const blogUrl = `${currentUrl}/blog/${id}`; // Full blog post URL

  // Array of share platforms
  const sharePlatforms = [
    {
      name: "Facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(blogUrl)}`,
      icon: <FacebookOutlined className="text-xl" />,
      bgColor: "bg-blue-600 hover:bg-blue-500",
    },
    {
      name: "Twitter",
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(blogUrl)}&text=${encodeURIComponent(blogTitle)}`,
      icon: <TwitterOutlined className="text-xl" />,
      bgColor: "bg-sky-500 hover:bg-sky-400",
    },
    {
      name: "LinkedIn",
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(blogUrl)}`,
      icon: <LinkedinOutlined className="text-xl" />,
      bgColor: "bg-blue-700 hover:bg-blue-600",
    },
  ];

  return (
    <div className="flex flex-row gap-3 mt-5">
      {sharePlatforms.map((platform) => (
        <a
          key={platform.name}
          href={platform.url}
          target="_blank"
          rel="noopener noreferrer"
          className={`flex items-center gap-2 px-4 py-2 text-white rounded ${platform.bgColor}`}
        >
          {platform.icon}
          <span>Share on {platform.name}</span>
        </a>
      ))}
    </div>
  );
};





