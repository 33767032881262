export default function MarketingFunnel() {
  return (
    <section className="py-8">
      <div className="max-w-screen-xl mx-auto px-4 flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-6">
        
        {/* Left Box */}
        <div className="bg-white shadow-md p-6 md:w-5/12 w-full rounded-tr-[50px] rounded-br-[50px] rounded-bl-[50px] transition-transform duration-300 hover:scale-105">
          <h2 className="text-2xl font-bold mb-3 border-l-4 border-blue-500 pl-3">
            Find Your Ideal Job on LinkedIn with swiftlead
          </h2>
          <p className="text-gray-700 mb-3 leading-relaxed">
            swiftlead simplifies your LinkedIn job search by automating key tasks, making your job hunt both efficient and effective. With swiftlead, you can set your job preferences, and the platform will automatically search for relevant job postings based on your criteria. It also allows you to reach out directly to hiring managers with personalized messages, introducing yourself and showcasing your expertise.
          </p>
          <p className="text-gray-700 leading-relaxed">
            Instead of spending hours scrolling through job listings, swiftlead helps you connect with the right opportunities faster. By automating profile visits and message sending, swiftlead saves you time and effort, so you can focus on preparing for interviews and landing your next career move.
          </p>
        </div>

        {/* Timeline Connector - Extended height */}
        <div className="  flex	 flex-col items-center">
          <div className="w-4 h-4 bg-blue-500 rounded-full border-2 border-white shadow mb-1"></div>
          <div className="w-1 bg-gray-300 h-24"></div> {/* Changed from h-12 to h-24 */}
          <div className="w-4 h-4 bg-blue-500 rounded-full border-2 border-white shadow mt-1"></div>
        </div>

        {/* Right Box - Increased vertical offset */}
        <div className="bg-white shadow-md p-6 md:w-5/12 w-full md:translate-y-16 transform rounded-tl-[50px] rounded-bl-[50px] rounded-br-[50px] transition-transform duration-300 hover:scale-105">
          <h2 className="text-2xl font-bold mb-3 border-l-4 border-blue-500 pl-3">
            Find Your Ideal Client on LinkedIn with swiftlead
          </h2>
          <p className="text-gray-700 mb-3 leading-relaxed">
            swiftlead is equally valuable for freelancers and business owners looking to find their ideal clients. By defining your ideal client profile, swiftlead automatically finds and connects you with potential leads that match your criteria.
          </p>
          <p className="text-gray-700 leading-relaxed">
            It allows you to send personalized messages to introduce your services, helping you establish connections with key decision-makers in your industry. swiftlead takes the work out of networking by automating outreach tasks, enabling you to expand your professional network quickly and effectively.
          </p>
        </div>
        
      </div>
    </section>
  );
}