import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Logo from "../../assets/logo.png";
import Drive from "../../assets/drive/07.png";
import Rocket from "../../assets/services/icon3.png";
import Chrome from "../../assets/services/icon1.png";
import Plan from "../../assets/services/icon2.png";
import { Helmet } from "react-helmet-async";


export default function WhyUs() {
  return (
    <>
      <Header />

      <Helmet>
        <title>Why Choose swiftlead: Your Trusted LinkedIn Automation Tool</title>
      </Helmet>
      <section
        id="iq-home"
        className="iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax"
        data-jarallax='{"speed": 0.6}'
        style={{ background: "url(images/bg/01.jpg)" }}
      >
        <div className="container-fluid">
          <div className="banner-text">
            <div className="row justify-content-between">
              <div className="col-lg-6 align-self-center">

                <h1 className="text-uppercase iq-font-white iq-tw-3 text-[24px]!">
                Land Interviews & Win Clients 10x Faster with swiftlead
                 {/* <span className="italic">
                    {" "}
                    —ALL IN ONE POWERFUL PLATFORM.
                  </span> */}
                </h1>

                <p className="iq-font-white iq-pt-18 iq-mb-40">
                  Swiftlead delivers job data and automates prospecting, helping you connect with employers or
                  clients efficiently and scale your outreach.
                </p>


              </div>
              <div className="col-lg-6">

              </div>
            </div>
          </div>
        </div>
        <div className="banner-objects">
          <span
            className="banner-objects-01"
            data-bottom="transform:translatey(50px)"
            data-top="transform:translatey(-50px);"
          >
            <img src={Drive} alt="drive02" />
          </span>
          <span className="banner-objects-02 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
          <span className="banner-objects-03 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
        </div>
      </section>





      <div className="main-content">
        <section
          id="software-features"
          className="overview-block-ptb iq-over iq-parallax"
          data-jarallax='{"speed": 0.6}'
          style={{
            background: "url(images/bg/05.jpg)",
            backgroundColor: "#fff",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading-title ">
                  <h3 className="title iq-tw-7">Why You Should Choose Us</h3>
                </div>
              </div>

              <div className="row ">
                <div className="col-sm-4">
                  <div className="flex gap-2 iq-services-box text-left">
                    <div className="iq-icon  hover:text-white ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-14"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                        />
                      </svg>
                    </div>
                    <div className="services-content">
                      <h5 className="iq-tw-6 iq-pb-10 ">
                        Targeted Insights at Your Fingertips
                      </h5>
                      <p>
                        swiftlead delivers essential data directly from job
                        postings, giving you quick access to decision-makers'
                        profiles, company details, and job specifics. Whether
                        you’re pursuing a new job or new clients, you'll have
                        everything you need to connect effectively.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="flex gap-2 iq-services-box text-left ">
                    <div className="iq-icon hover:text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-14"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                        />
                      </svg>
                    </div>
                    <div className="services-content">
                      <h5 className="iq-tw-6 iq-pb-10">
                        Seamless Browser-Based Integration
                      </h5>
                      <p>
                        Our tool operates right in your browser, discreetly
                        gathering data without detection or complicated setups.
                        Enjoy a seamless, automated experience that saves time
                        while delivering valuable insights to support your
                        outreach.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="flex gap-2 iq-services-box text-left">
                    <div className="iq-icon hover:text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-14"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
                        />
                      </svg>
                    </div>
                    <div className="services-content">
                      <h5 className="iq-tw-6 iq-pb-10 ">
                        Amplify Your Outreach Strategy
                      </h5>
                      <p>
                        swiftlead streamlines prospecting by removing the
                        tedious steps, letting you focus on personalizing
                        communication for each opportunity. Build meaningful
                        connections with potential employers or clients,
                        effortlessly scaling your reach and impact.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

     


      <Footer />

    </>
  )
}