import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Logo from "../../assets/logo.png";
import Drive from "../../assets/drive/07.png";
import { CaretRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { generateDate } from '../../utils/datehelper';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { Flex, Tag } from 'antd';
import DOMPurify from "dompurify";
import { Helmet } from 'react-helmet';

const colorcodes = ['volcano', 'geekblue', 'green', 'magenta']

// firstly i want as per index take color codes when the index is last then it will again go to first color for again rest the color code
function getcolor(index) {
  return colorcodes[index % colorcodes.length];
}
const BlogPage = () => {
  const navigate = useNavigate();
  const [allblogs, setAllBlogs] = useState([]);
  const [allcat, setallcat] = useState([]);
  const [nodatafound, setNodatafound] = useState(null)
  const [loading, setLoading] = useState(false);
  const [isallcatload, setisallcatload] = useState(false);
  const location = useLocation();


  useEffect(() => {
    async function fetchBlogs() {
      setLoading(true)
      try {
        const searchParams = new URLSearchParams(location.search);
        const searchCategory = searchParams.get("search");
        console.log(searchCategory)
        // ${searchCategory ? `?search=${searchCategory}` : ""}
        const url = `/api/blogs/getblogs${searchCategory ? `?search=${searchCategory}` : ""}`
        const response = await fetch(
          url,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          if (result.data.length === 0) {
            setNodatafound("ohh! There is no blog found for this content.")
            return;
          }
          setNodatafound(null)
          setAllBlogs(result.data.filter(item => item.isActive));
        }
      } catch (error) {
        console.log(error)
      }
      finally {
        setLoading(false)
      }
    }

    fetchBlogs();
  }, [location.search]);


  useEffect(() => {
    async function fetchcat() {
      try {
        setisallcatload(true)
        const response = await fetch("/api/blogs/categories", {
          method: "GET",
          headers: {
            "Content-Type": "application/json", // Ensure proper content type
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          // console.error("Error fetching blogs:", errorData);
        } else {
          const result = await response.json();
          setallcat(result.data)
        }
      } catch (error) {

      } finally {
        setisallcatload(false)
      }
    }
    fetchcat()
  }, [])


  const createSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with "-"
      .replace(/^-+|-+$/g, ""); // Remove leading/trailing "-"
  };

  const handlerblog = (name) => {
    const slug = createSlug(name);
    navigate(`/blog/${slug}`);
  };




  return (
    <>
      <Header />
      <Helmet>
              <title>swiftlead Blog: Tips for Job Seekers & Client Acquisition</title>
            </Helmet>

      <section
        id="iq-home"
        className="iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax"
        data-jarallax='{"speed": 0.6}'
        style={{ background: "url(images/bg/01.jpg)" }}
      >
        <div className="container-fluid">
          <div className="banner-text">
            <div className="row justify-content-between">
              <div className="col-lg-6 align-self-center">

                <h1 className="text-uppercase iq-font-white iq-tw-3 text-[24px]!">
                swiftlead Blog: Strategies for LinkedIn Job Search & Client Acquisition
                  {/* <span className="italic">
                    {" "}
                    —ALL IN ONE POWERFUL PLATFORM.
                  </span> */}
                </h1>

                <p className="iq-font-white iq-pt-18 iq-mb-40">
                  offering expert insights, industry trends to helps navigate the evolving job market.our blog provides content to keep you informed and ahead of the competition.
                </p>


              </div>
              <div className="col-lg-6">

              </div>
            </div>
          </div>
        </div>
        <div className="banner-objects">
          <span
            className="banner-objects-01"
            data-bottom="transform:translatey(50px)"
            data-top="transform:translatey(-50px);"
          >
            <img src={Drive} alt="drive02" />
          </span>
          <span className="banner-objects-02 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
          <span className="banner-objects-03 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
        </div>
      </section>


      <div className="container mx-auto py-8 px-4 md:px-4">
        {
          nodatafound && <p className="text-[#c18383]  bg-red-100 p-2 font-medium text-sm w-full capitalize rounded">{nodatafound}</p>
        }

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

          {/* Left Side - Blog Cards */}

          <div className="md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-10 auto-rows-min items-start ">

            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-10 auto-rows-min items-start grid-auto-flow-dense"> */}
            {
              loading ?
                Array.from({ length: 4 }).map((_, index) => (
                  <div key={index} className="bg-white shadow-md rounded-lg p-4 animate-pulse">
                    <div className="w-full h-48 bg-gray-300 rounded-md"></div>
                    <div className="mt-4 h-6 w-1/2 bg-gray-300 rounded"></div>
                    <div className="mt-2 h-4 w-3/4 bg-gray-300 rounded"></div>
                    <div className="mt-2 h-4 w-5/6 bg-gray-300 rounded"></div>
                    <div className="mt-4 h-10 w-full bg-gray-300 rounded"></div>
                  </div>
                ))
                : allblogs?.map((post) => (
                  <div key={post.uuid} className="bg-white shadow-md rounded-lg overflow-hidden flex flex-col">
                    {/* Image Section */}
                    {post.image ? (
                      <img src={post.image} alt="Post" className="w-full max-h-[250px] object-cover" />
                    ) : (
                      <div className="w-full min-h-[150px] bg-gray-200 flex items-center justify-center">
                        <span className="text-gray-500 text-sm">No Image</span>
                      </div>
                    )}

                    {/* Content Section */}
                    <div className="p-4 flex flex-col">


                      <div className="text-sm text-gray-600 flex items-center flex-wrap">
                        {post.writer_name && (
                          <>
                            <span>By <span className="text-blue-600">{post.writer_name}</span></span>
                            <span className="mx-2 text-gray-400">|</span>
                          </>
                        )}
                        <span>Date : {generateDate(post.created_at)}</span>
                      </div>
                      <h2 className="text-lg font-semibold text-gray-800 mt-3 capitalize">{post.name}</h2>
                      {/* Category Buttons */}
                      {
                        post?.blog_categories?.length > 0 && (

                          <Flex gap="4px 0" wrap>
                            {post?.blog_categories?.map((item, index) => (
                              <Tag key={index} color={getcolor(index)}>
                                {item.categories.name.slice(0, 18)}{item.categories.name.length > 20 && '..'}
                              </Tag>
                            ))}
                          </Flex>
                        )
                      }
                      <p className="text-gray-700 text-sm mt-2 leading-6">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(post.description.length > 170
                              ? `${post.description.slice(0, 170)}...`
                              : post.description)
                          }}
                        />
                        {/* {post.description.length > 170 && <span className="text-blue-500"> Read More</span>} */}
                      </p>

                      {/* Read More Button */}
                      <button
                        onClick={() => handlerblog(post.name)}
                        className="mt-1 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 self-start"
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                ))}
          </div>

          {/* Sidebar */}
          <aside className="space-y-8">
            {/* Search */}



            {/* Categories */}
            <div className="bg-white shadow-md rounded-md p-4 mb-6">
              <h3 className="text-xl font-semibold mb-4 text-black">Categories</h3>
              <ul className="space-y-2 text-gray-700">
                {
                  isallcatload && <>

                    <div className="mt-4 h-6 w-1/2 bg-gray-300 rounded  animate-pulse"></div>
                    <div className="mt-2 h-4 w-3/4 bg-gray-300 rounded  animate-pulse"></div>
                    <div className="mt-2 h-4 w-5/6 bg-gray-300 rounded  animate-pulse"></div>

                  </>
                }
                {
                  !isallcatload && allcat?.map((category, index) => (
                    <li key={index} className=" text-black border-b-[1px]  border-slate-5000">
                      <p>
                        <Link to={`/blog?search=${category.name}`} className='text-black'>
                          <CaretRightOutlined className='text-blue-600' /> {category.name}
                        </Link>
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
          </aside>
        </div>


      </div>

     

      <Footer />

    </>
  );
};

export default BlogPage;
