import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Logo from "../../assets/logo.png";
import Drive from "../../assets/drive/07.png";
import { InfoCircleOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useState } from "react";
import { message } from "antd";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    comment: "",
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("/api/contact/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(data)
        message.success("Contact posted successfully!");
        setFormData({ name: "", email: "", subject: "", comment: "" }); // Reset form
      } else {
        message.error("Failed to post blog.");
      }
      // alert(response.data.message);

    } catch (error) {
      alert("Error submitting form");
    }
  };
  return (
    <>
      <Header />
      <Helmet>
              <title>Contact swiftlead: Get in Touch for LinkedIn Solutions</title>
            </Helmet>

      <section
        id="iq-home"
        className="iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax"
        data-jarallax='{"speed": 0.6}'
        style={{ background: "url(images/bg/01.jpg)" }}
      >
        <div className="container-fluid">
          <div className="banner-text">
            <div className="row justify-content-between">
              <div className="col-lg-6 align-self-center">

                <h1 className="text-uppercase iq-font-white iq-tw-3 text-[24px]!">
                Get in Touch with swiftlead: We’re Here to Help!
                  {/* <span className="italic">
                    {" "}
                    —ALL IN ONE POWERFUL PLATFORM.
                  </span> */}
                </h1>

                <p className="iq-font-white iq-pt-18 iq-mb-40">
                  We’re here to help! Whether you have questions about job listings, need support, or want to partner with us, feel free to reach out.
                </p>


              </div>
              <div className="col-lg-6">

              </div>
            </div>
          </div>
        </div>
        <div className="banner-objects">
          <span
            className="banner-objects-01"
            data-bottom="transform:translatey(50px)"
            data-top="transform:translatey(-50px);"
          >
            <img src={Drive} alt="drive02" />
          </span>
          <span className="banner-objects-02 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
          <span className="banner-objects-03 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
        </div>
      </section>

      <div className="flex flex-col lg:flex-row items-center justify-center py-12 px-6 lg:px-40 space-y-8 lg:space-y-0 lg:space-x-12">
        {/* Left Side: Contact Form */}
        <div className="w-full lg:w-1/2">
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Name"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Email"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Subject</label>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Subject"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Comment</label>
              <textarea
                name="comment"
                value={formData.comment}
                onChange={handleChange}
                rows="4"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Message"
              ></textarea>
            </div>
            <button type="submit" className="bg-blue-600 text-white py-3 px-10 rounded-lg hover:bg-blue-700">
              Submit
            </button>
          </form>
        </div>

        {/* Right Side: Contact Information */}
        <div className="w-full lg:w-1/2 space-y-6">
          <div>
            <h3 className="text-lg font-bold text-blue-600 mb-2">Get In Touch</h3>
            <h2 className="text-2xl font-medium text-gray-800">Have Questions? Reach Out for Support
            </h2>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200 flex flex-row">
            <MailOutlined className="text-blue-600 text-4xl" />
            <div className="ml-4">
              <h4 className="text-lg font-semibold text-gray-800">Email</h4>
              <p className="text-gray-600">support@swiftlead.io</p>
            </div>
          </div>
          {/* <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200 flex flex-row">
            <PhoneOutlined className="text-blue-600 text-4xl" />
            <div className="ml-4">
              <h4 className="text-lg font-semibold text-gray-800">Phone</h4>
              <p className="text-gray-600">+1 234 567 890</p>
            </div>
          </div> */}
          {/* <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200 flex flex-row">
            <InfoCircleOutlined className="text-blue-600 text-4xl" />
            <div className="ml-4">
              <h4 className="text-lg font-semibold text-gray-800">Address</h4>
              <p className="text-gray-600">123 Main Street, City, Country</p>
            </div>
          </div> */}
        </div>
      </div>

      

      <Footer />

    </>
  );
};

export default ContactUs;
