import React from "react";

function Headline({ text }) {
  return (
    <div
      // style={{
      //   background:
      //     "linear-gradient(to top, rgb(237, 242, 249) 0%, rgb(254, 255, 255) 40%)",
      // }}
      className="bg-gradient-to-b from-[#a7a7f1] to-[#feffff]"
    >
      <div className="w-full h-[200px] font-bold flex flex-col justify-center items-center">
        <h1 className="text-2xl text-[#393857] md:text-4xl mt-20">{text}</h1>
        <p className="mb-4">Effective Date: May 17, {new Date().getFullYear()}</p>
      </div>
    </div>
  );
}

export default Headline;
