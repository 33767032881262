import React, { useState, useEffect } from "react";
import {
  Table,
  DatePicker,
  Button,
  Input,
  Row,
  Col,
  Alert,
  Modal,
  FloatButton,
} from "antd";
import moment from "moment";
import Papa from "papaparse";
import { createClient } from "../../utils/supabase/client";
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
const { Search } = Input;
const { confirm } = Modal;

const JobTable = ({ jobData }) => {
  const supabase = createClient();
  const [filteredData, setFilteredData] = useState([]); // Initialize as empty array
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchValue, setSearchValue] = useState(""); // State to manage search input
  const [duplicateJobs, setDuplicateJobs] = useState([]); // State to track duplicates
  const [selectedRows, setSelectedRows] = useState([]); // State to manage selected rows for deletion
  const [loadings, setLoadings] = useState([false]);
  const [showDuplicateTable, setShowDuplicateTable] = useState(false); // State to toggle visibility of duplicate jobs table

  useEffect(() => {
    if (jobData && jobData.length) {
      setFilteredData(jobData); // Make sure filteredData is updated with jobData
    }
  }, [jobData]);

  useEffect(() => {
    findDuplicates(); // Recalculate duplicates when filteredData changes
  }, [filteredData]);

  const findDuplicates = () => {
    const duplicates = [];
    const seen = new Set();

    // Ensure filteredData is an array before calling forEach
    (filteredData || []).forEach((job) => {
      const uniqueKey = `${job.company_name}-${job.job_title}-${job.job_location}`;
      if (seen.has(uniqueKey)) {
        duplicates.push(job);
      } else {
        seen.add(uniqueKey);
      }
    });

    setDuplicateJobs(duplicates);
  };

  const columns = [
    {
      title: "Contact Photo",
      dataIndex: "contact_photo",
      key: "contact_photo",
      render: (text) => (
        <img
          src={text}
          alt="Profile"
          style={{
            width: 50,
            height: 50,
            borderRadius: "10%",
          }}
        />
      ),
    },
    {
      title: "Contact Name",
      dataIndex: "contact_name",
      key: "contact_name",
    },
    {
      title: "Contact Title",
      dataIndex: "contact_title",
      key: "contact_title",
    },
    {
      title: "Contact Profile",
      dataIndex: "contact_profile",
      key: "contact_profile",
      render: (text) =>
        text === "Profile link not found" ? (
          "Profile link not found"
        ) : (
          <a href={text} target="_blank" rel="noopener noreferrer">
            View Profile
          </a>
        ),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Company Profile",
      dataIndex: "company_profile",
      key: "company_profile",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          View Company
        </a>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Job Location",
      dataIndex: "job_location",
      key: "job_location",
    },
    {
      title: "Job Type",
      dataIndex: "job_type",
      key: "job_type",
    },
    {
      title: "Job Link",
      dataIndex: "view_job",
      key: "view_job",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          View Job
        </a>
      ),
    },
    {
      title: "Search Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => moment(text).format("DD MMMM YYYY [at] hh:mm A"),
    },
  ];

  const handleFilter = (value) => {
    if (!value) {
      setFilteredData(jobData);
    } else {
      const filteredJobs = jobData.filter((job) =>
        moment(job.created_at).isSame(moment(value), "day")
      );
      setFilteredData(filteredJobs);
    }
  };

  const handleClearFilter = () => {
    setFilteredData(jobData);
    setSelectedDate(null);
    setSearchValue(""); // Clear search value
  };

  const handleDownloadCSV = () => {
    // Rearrange the columns in the desired order
    const rearrangedData = filteredData.map((item) => ({
      id: item.id,
      contact_photo: item.contact_photo,
      contact_name: item.contact_name,
      contact_title: item.contact_title,
      contact_profile: item.contact_profile,
      company_name: item.company_name,
      company_profile: item.company_profile,
      job_title: item.job_title,
      job_location: item.job_location,
      job_type: item.job_type,
      view_job: item.view_job,
      company_logo: item.company_logo,
    }));

    // Define the custom header structure
    const customHeader = [
      ["********* swiftlead.io ******"], // Swiftlead.io styled as a title
      ["********* JOB OPPORTUNITY DATA *********"], // Job opportunity data title
    ];

    // Convert custom header and column headers to CSV format
    const headerCSV = Papa.unparse(customHeader, { header: false });
    // Convert rearranged data to CSV format
    const dataCSV = Papa.unparse(rearrangedData);

    // Combine header, column header, and data
    const csvContent = `${headerCSV}\n${dataCSV}`;

    // Create a downloadable blob
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "swiftlead.io_job_opportunity_data.csv";
    link.click();
  };

  const onSearch = (value) => {
    setSearchValue(value); // Update search state
    if (!value) {
      setFilteredData(jobData); // Reset to full data if search is empty
      return;
    }

    const lowerCaseValue = value.toLowerCase();

    const filteredJobs = jobData.filter((job) =>
      ["company_name", "job_title", "job_location", "contact_title"].some(
        (key) => job[key]?.toLowerCase().includes(lowerCaseValue)
      )
    );

    setFilteredData(filteredJobs);
  };

  const handleDeleteSelected = async () => {
    try {
      // Set loading to true when starting the request
      setLoadings([true]);

      // Get JWT token for the user that is logged in
      const userToken = (await supabase.auth.getSession()).data.session
        .access_token;

      // Make sure userToken is set before making the request
      if (!userToken) {
        throw new Error("User token is missing.");
      }

      // Hit the API to delete jobs
      const resp = await fetch("/api/dashboard/deleteOwnJobsOfAUser", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json", // Make sure to set the content type to JSON
        },
        body: JSON.stringify({
          jobIds: selectedRows, // Send the selected job IDs in the request body
        }),
      });

      const data = await resp.json();

      if (resp.ok) {
        // Update the state to reflect the remaining jobs
        const remainingJobs = filteredData.filter(
          (job) => !selectedRows.includes(job.id)
        );

        setFilteredData(remainingJobs);
        setSelectedRows([]); // Clear selected rows after deletion

        // Optionally reload the window after successful deletion
        window.location.reload();
      } else {
        alert(data.error || "Failed to delete jobs");
      }
    } catch (error) {
      alert(error.message || "An error occurred");
    } finally {
      // Set loading to false after the request is complete
      setLoadings([false]);
    }
  };

  const showPropsConfirm = () => {
    confirm({
      title: "Are you sure you want to delete these job searches?",
      icon: <ExclamationCircleFilled />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeleteSelected(); // Call the delete function when confirmed
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys); // Update selected rows state
    },
  };

  return (
    <div className="md:p-10 space-y-6">
      <div className="grid md:grid-cols-2 space-x-2">
        <div className="gap-2 sm:flex sm:flex-row">
          <div className="mb-4 flex gap-2 justify-between">
            <Button
              size="large"
              style={{ width: "126px" }}
              onClick={handleClearFilter}
            >
              Clear Filter
            </Button>
            <Button size="large" onClick={handleDownloadCSV}>
              Download CSV
            </Button>
            {selectedRows.length > 0 && (
              <FloatButton
                size="large"
                type="danger"
                onClick={showPropsConfirm}
                disabled={selectedRows.length === 0}
                loading={loadings[0]}
                style={{
                  position: "fixed",
                  bottom: "20px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: loadings[0] ? "#ff4d4f" : "#ff4d4f",
                  borderColor: "#ff4d4f",
                  width: "60px",
                  height: "60px",
                  opacity: loadings[0] ? 0.6 : 1,
                }}
                icon={<DeleteOutlined style={{ color: "white" }} />}
              >
                Delete Selected
              </FloatButton>
            )}
          </div>

          <Row justify="center">
            <Col xs={24} sm={12} md={8} lg={24}>
              <DatePicker
                size="large"
                value={selectedDate ? moment(selectedDate) : null}
                onChange={(date) => {
                  setSelectedDate(date);
                  handleFilter(date ? date.format() : null);
                }}
                style={{ marginBottom: 16, width: "100%" }}
              />
            </Col>
          </Row>
        </div>
        <div style={{ marginLeft: "1px" }}>
          <Search
            placeholder="Search by Company, Title, Location, or Contact"
            allowClear
            size="large"
            value={searchValue} // Bind search input to state
            onSearch={onSearch}
            onChange={(e) => setSearchValue(e.target.value)} // Update state on input change
            style={{ width: "100%" }}
            enterButton={
              <button className="custom-search-button">Search</button>
            }
          />
        </div>
      </div>

      {/* Alert for duplicate job searches */}
      {duplicateJobs.length > 0 && (
        <>
          <Alert
            message={
              <span className="flex justify-between text-black">
                Found {duplicateJobs.length} duplicate jobs.
                <Button
                  size="small"
                  type="link"
                  style={{
                    outline: "none",
                    boxShadow: "none",
                    color: "black",
                  }}
                  onClick={() => setShowDuplicateTable(!showDuplicateTable)}
                >
                  {showDuplicateTable ? (
                    <div className="flex gap-1 justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                      <span>Hide Duplicates</span>
                    </div>
                  ) : (
                    <div className="flex gap-1 justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                      <span>Show Duplicates</span>
                    </div>
                  )}
                </Button>
              </span>
            }
            type="warning"
            showIcon
            style={{
              marginBottom: 16,
              backgroundColor: "rgba(82, 113, 255, 0.2)",
              borderColor: "#5271ff",
              color: "#fff",
            }}
          />
          <div
            className={`smooth-transition ${
              showDuplicateTable ? "visible" : "hidden"
            }`}
          >
            {/* Duplicate table content goes here */}
            <p>Duplicate jobs table content...</p>
          </div>
        </>
      )}

      {/* Duplicate Jobs Table */}
      {showDuplicateTable && (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={duplicateJobs}
          pagination={{ pageSize: 10 }}
          scroll={{ x: 800 }}
          rowKey="id"
          className="custom-table"
        />
      )}

      {/* All Jobs table */}
      {showDuplicateTable === false && (
        <Table
          dataSource={filteredData}
          columns={columns}
          rowKey="id"
          bordered
          scroll={{ x: 800 }}
          rowSelection={rowSelection}
          className="custom-table"
        />
      )}
    </div>
  );
};

export default JobTable;
