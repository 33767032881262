import React from "react";
import { StarFilled } from "@ant-design/icons";
import person from "../../assets/services/person.jpg";
import person1 from "../../assets/services/person1.jpg";
import person2 from "../../assets/services/person2.jpg";

const Testimonial = () => {

  const testimonials = [
    {
      text: "swiftlead revolutionized my job search! The platform made it incredibly easy to connect with hiring managers directly. I landed my dream job much faster than I ever imagined, and the whole process felt seamless.",
      name: "John D. ",
      title: " Job Seeker",
      img:person1
    },
    {
      text: "I’ve been using swiftlead to find clients, and the results have been incredible! It helped me expand my network, leading to more projects and better opportunities.",
      name: "Lily K.",
      title: "Freelancer",
      img:person
    },
    {
      text: "swiftlead has streamlined our lead generation, saving time and boosting client connections. We've seen a significant increase in sales opportunities and closed deals!",
      name: "Mark T.",
      title: "Sales Manager",
      img:person2
    },
  ];

  return (
    <section className=" relative z-[2] mt-5 mb-5">
      {/* Container */}
      <div className="max-w-[1140px] w-full mx-auto px-[15px]">
        {/* Section Title */}
        <div className="  relative text-center">
          <div className="heading-title">
            <h2 className="title iq-tw-7"> What Clients Are Saying About swiftlead </h2>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {testimonials.map((item, i) => (
            <div
              key={i}
              className="border-[5px] border-[#5271ff] text-center rounded-[45px] shadow-lg relative z-[2] mx-3 transition transform duration-300 hover:-translate-y-2"
            >
              <div className="round-1 w-full h-full absolute z-[1] before:content-[''] before:absolute before:left-[88px] before:top-[-7px] before:w-[50px] before:h-[7px] before:bg-white before:rounded-[30px] after:content-[''] after:absolute after:left-[-7px] after:top-[62px] after:w-[7px] after:h-[50px] after:bg-white after:rounded-[30px]" />
              <div className="round-2 w-full h-full absolute z-[1] before:content-[''] before:absolute before:right-[87px] before:bottom-[-7px] before:w-[50px] before:h-[7px] before:bg-white before:rounded-[30px] after:content-[''] after:absolute after:right-[-7px] after:bottom-[62px] after:w-[7px] after:h-[50px] after:bg-white after:rounded-[30px]" />

              <p className=" text-[15px] leading-[24px] px-[20px] pb-[20px] pt-[20px] relative z-[3] ">
                {item.text}
              </p>

              {/* Star Rating */}
              <div className="flex justify-center mb-2">
                <StarFilled className="w-5 h-5 text-yellow-400" />
                <StarFilled className="w-5 h-5 text-yellow-400" />
                <StarFilled className="w-5 h-5 text-yellow-400" />
                <StarFilled className="w-5 h-5 text-yellow-400" />
                <StarFilled className="w-5 h-5 text-yellow-400" />
              </div>

              <div className="relative z-[3] flex items-center justify-center text-left ">
                <div className="pr-[15px]">
                  <img
                    src={item.img}
                    alt="client"
                    className="rounded-full w-[50px] h-[50px] object-cover"
                  />
                </div>
                <div>
                  <h6 className="font-[700] text-[18px]">
                    {item.name}
                  </h6>
                  <span className="inline-block  text-[12px]">
                    {item.title}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
