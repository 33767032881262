import React, { useState } from "react";

function FAQPage() {
  const faqData = [
    {
      title: "What is swiftlead?",
      description: "swiftlead is a platform that automates job and client searches on LinkedIn, connecting job seekers and businesses with the right opportunities quickly and efficiently."
    },
    {
      title: "How does swiftlead help job seekers?",
      description: "swiftlead automates job search, sending personalized messages to hiring managers and targeting relevant job listings, speeding up the job hunt process."
    },
    {
      title: "How do I get started with swiftlead?",
      description: "Simply sign up, choose your plan, and install the Chrome extension to begin automating your job or client search."
    },
    {
      title: "Is swiftlead secure?",
      description: "Yes, swiftlead ensures secure and private connections while automating LinkedIn tasks within platform guidelines."
    },
    {
      title: "Do I need a LinkedIn account to use swiftlead?",
      description: "Yes, swiftlead integrates with LinkedIn to automate job and client searches."
    },
    {
      title: "How does swiftlead save time?",
      description: "swiftlead automates repetitive job search tasks, letting you focus on applying instead of manually searching."
    },
    {
      title: "Can swiftlead be used for freelance work?",
      description: "Yes, swiftlead helps freelancers find clients by automating client searches on LinkedIn."
    },
    {
      title: "Can I target specific industries or companies with swiftlead?",
      description: "Yes, you can filter and target clients by industry, company size, or role to tailor your outreach."
    },
    {
      title: "How can the AI messaging feature improve my outreach?",
      description: "The AI messaging feature helps you send personalized, professional messages in less time, increasing your outreach volume and engagement with prospects."
    },
    {
      title: "Who Can Use swiftlead?",
      description: "swiftlead is ideal for job seekers, freelancers, businesses, sales teams, entrepreneurs, and marketing professionals."
    }
  ];

  const [isOpen, setIsOpen] = useState(null);
  const handleToggle = (idx) => {
    setIsOpen((prevIdx) => (prevIdx === idx ? null : idx));
  };

  const leftFaq = faqData.slice(0, 5);
  const rightFaq = faqData.slice(5, 10);

  return (
    <div>
      <div className="container mx-auto">
        <div className="my-10">
         
          <div className="heading-title">
                <h2 className="title iq-tw-7"> Common Questions about swiftlead</h2>
                
                </div>
          <div className=" px-4 md:px-8 lg:px-16">
            <div className="flex flex-col md:flex-row gap-8 md:gap-12 items-start">
              <div className="flex-1 w-full">
                <div className="flex w-full justify-center">
                  <div className="w-full max-w-[1100px] cursor-pointer">
                    <div className="flex flex-col md:flex-row gap-8 w-full">
                      {/* Left Column */}
                      <div className="w-full md:w-1/2 space-y-6">
                        {leftFaq.map((data, idx) => (
                          <div key={idx} onClick={() => handleToggle(idx)} className="flex items-center">
                            <div className="flex size-16 select-none items-center justify-center rounded-md bg-[#5271ff] text-[18px] font-semibold text-white">
                              <span>{String(idx + 1).padStart(2, '0')}</span>
                            </div>
                            <div className="relative h-[2px] w-10 bg-[#5271ff]">
                              <span className="absolute -left-2 -top-[5px] z-40 h-3 w-3 rounded-full border-2 border-[#5271ff] bg-white"></span>
                              <span className="h-1 w-10 bg-[#5271ff]"></span>
                              <span className={`absolute -right-2 -top-[5px] z-40 h-3 w-3 rounded-full border-2 ${isOpen === idx ? 'border-[#5271ff] bg-white delay-100' : 'border-transparent'}`}></span>
                            </div>
                            <div className="text-center">
                              <div className="relative max-w-[450px] border-t-[12px] border-[#5271ff] p-3 shadow-md">
                                <span className="absolute right-0 top-0 h-0 w-0 border-b-[40px] border-r-[40px] border-b-transparent border-r-[#5271ff]"></span>
                                <h1 className="select-none text-lg text-[#5271ff]">{data.title}</h1>
                              </div>
                              <div className={`grid overflow-hidden text-[#5271ff] transition-all duration-300 ease-in-out ${isOpen === idx ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"}`}>
                                <div className="overflow-hidden bg-gray-100">
                                  <div className="max-w-[450px] shadow font-medium text-[16px] p-6 text-sm text-[#5271ff]">{data.description}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Right Column */}
                      <div className="w-full md:w-1/2 space-y-6">
                        {rightFaq.map((data, idx) => {
                          const originalIndex = idx + 5;
                          return (
                            <div key={originalIndex} onClick={() => handleToggle(originalIndex)} className="flex items-center">
                              <div className="flex size-16 select-none items-center justify-center rounded-md bg-[#5271ff] text-[18px] font-semibold text-white">
                                <span>{String(originalIndex + 1).padStart(2, '0')}</span>
                              </div>
                              <div className="relative h-[2px] w-10 bg-[#5271ff]">
                                <span className="absolute -left-2 -top-[5px] z-40 h-3 w-3 rounded-full border-2 border-[#5271ff] bg-white"></span>
                                <span className="h-1 w-10 bg-[#5271ff]"></span>
                                <span className={`absolute -right-2 -top-[5px] z-40 h-3 w-3 rounded-full border-2 ${isOpen === originalIndex ? 'border-[#5271ff] bg-white delay-100' : 'border-transparent'}`}></span>
                              </div>
                              <div className="text-center">
                                <div className="relative max-w-[450px] border-t-[12px] border-[#5271ff] p-3 shadow-md">
                                  <span className="absolute right-0 top-0 h-0 w-0 border-b-[40px] border-r-[40px] border-b-transparent border-r-[#5271ff]"></span>
                                  <h1 className="select-none text-lg text-[#5271ff]">{data.title}</h1>
                                </div>
                                <div className={`grid overflow-hidden text-[#5271ff] transition-all duration-300 ease-in-out ${isOpen === originalIndex ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"}`}>
                                  <div className="overflow-hidden bg-gray-100">
                                    <div className="max-w-[450px] shadow font-medium text-[16px] p-6 text-sm text-[#5271ff]">{data.description}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQPage;